@font-face {
  font-family: 'LiberisDisplay';
  font-weight: 400;
  src: url(./fonts/LiberisDisplay-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'FoundryPlek';
  font-weight: 700;
  src: url(./fonts/FoundryPlek-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'LiberisDisplay';
  font-weight: 700;
  src: url(./fonts/LiberisDisplay-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'LiberisDisplay';
  font-weight: 600;
  src: url(./fonts/LiberisDisplay-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'ModernGothic';
  font-weight: 600;
  src: url(./fonts/ModernGothic-Light.otf) format('opentype');
}

body {
  font-family: "ModernGothic"!important;
  font-weight: 400;
  white-space: normal;
  background-color: #F4F4EC;
}

html {
  background-color: '#F4F4EC';
}

@keyframes border-pulsate {
  from {
    border: solid 0px #E7FF7C
  }

  to {
    border: solid 10px #E7FF7C
  }
}

@keyframes flexi-border-pulsate {
  from {
    border: solid 0px #6266f1
  }

  to {
    border: solid 10px #6266f1
  }
}



.active {
  padding: -10px;
  border: 15px solid #E7FF7C;
  animation: border-pulsate 2s infinite;
  border-radius: 10px;
  text-align: center;
}

.flexi-active {
  padding: -10px;
  border: 15px solid #E7FF7C;
  animation: flexi-border-pulsate 2s infinite;
  border-radius: 10px;
  text-align: center;
}



#root {
  height: auto;
  min-height: 100%;
}


.circle {
  background: #6537e24b;
  width: 330px;
  height: 330px;
  margin: auto;
  border-radius: 100%;
  overflow: hidden;
  z-index: -1;

  span {
    display: block;
    font-size: 240px;
    color: #fff;
    height: 300px;
    width: 300px;
    text-align: center;
    padding-top: 24%;
  }

}

/*.circle:hover {
  background: #070338;
}
*/

.circle {
  -webkit-animation: grow 3s infinite;

}

@keyframes grow {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0);
  }
}


.bar {
  width: 30px;
  margin: 50px;
  height: 0;
  position: relative;
  top: 100px;
  transform-origin: top;
  transform: translate(0%, 0%) rotate(180deg);
}


.barsmall {
  width: 30px;
  margin: 50px;
  position: relative;
  top: 100px;
  transform-origin: top;
  transform: translate(0%, 0%) rotate(180deg);
  animation: growbarsmall 2s forwards;
}

.barmed {
  width: 30px;
  margin: 50px;
  position: relative;
  top: 100px;
  transform-origin: top;
  transform: translate(0%, 0%) rotate(180deg);
  animation: growbarmedium 2s forwards;
}

.barlarge {
  width: 30px;
  margin: 50px;
  position: relative;
  top: 100px;
  transform-origin: top;
  transform: translate(0%, 0%) rotate(180deg);
  animation: growbarlarge 2s forwards;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.fade-in-text-2 {
  animation: fadeIn 3s;
}

.fade-in-text-1 {
  animation: fadeIn 1.5s;
}

@keyframes growbarsmall {
  from {
    height: 0px;
  }

  to {
    height: 60px;
  }
}

@keyframes growbarmedium {
  from {
    height: 0px;
  }

  to {
    height: 90px;
  }
}

@keyframes growbarlarge {
  from {
    height: 0px;
  }

  to {
    height: 130px;
  }
}

@keyframes growbar {
  from {
    height: 0px;
  }

  to {
    height: 130px;
  }
}



.split {
  background: linear-gradient(-45deg, #6437e2 12.5%, transparent 12.5%, transparent 25%, #6437e2 25%, #6437e2 37.5%, transparent 37.5%, transparent 50%, #6437e2 50%, #6437e2 62.5%, transparent 62.5%, transparent 75%, #6437e2 75%, #6437e2 87.5%,
      transparent 87.5%,
      transparent 100%,
      #6437e2 100%);
  margin-bottom: 10px;
}


.reset-empty {
  font-size: 10px;
  cursor: pointer;
  writing-mode: vertical-rl;
  text-orientation: upright;
  border-radius: 15px;
  border: 1px solid #00000030;
  padding: 2px
}

.reset {
  font-size: 10px;
  cursor: pointer;
  writing-mode: vertical-rl;
  text-orientation: upright;
  border-radius: 15px;
  border: 1px solid #f04337;
  background-color: #f04337c2;
  color: white;
  padding: 2px
}

.reset-empty:hover {
  background-color:
    #fed6d7;
}

.selected {
  background-color: #E7FF7C !important;
  color: white !important;
}